import {connect} from "react-redux";
import Input from "../../Base/BaseComponents/Input";
import Form from "../../Base/Form";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {completeSaveNewMenuItem, completeDeleteMenuItem} from "../../Base/reducers/RestaurantReducer";

import {saveData} from "../../functions";
import {response} from "../../Base/reducers/ModalReducer";
import LoadingContainer from "../../Base/Loading/LoadingContainer";

const MyMenuListElements = (props) => {
    const initialNewValues = {
        ID           : props?.item?.ID || null,
        type_id      : props?.type_id || null,
        Restaurant_ID: props?.Restaurant_ID || null,
        Item         : props?.item?.Item || '',
        Description  : props?.item?.Description || '',
        Price        : props?.item?.Price || '',
    }
    const [newValues, setNewValue] = useState(initialNewValues);
    const [valueWarnings, setValueWarnings] = useState({
        Item       : false,
        Description: false,
        Price      : false,
    })

    const [edit, setEdit] = useState(props.edit || false);

    useEffect(() => {
        const handleAction = (actionDataJSON) => {
            const actionData = JSON.parse(actionDataJSON);
            if(actionData && actionData.action && actionData.data) {
                props[actionData.action](actionData.data);
            }
        }

        props.socket.on('action', handleAction)

        props.socket.emit('joinRoom', props?.Restaurant_ID);

        return () => {
            props.socket.off('action', handleAction);
            props.socket.disconnect();
        }
    },[])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    useEffect(() => {
        setEdit(false);
    }, [props.stopEdit])

    useEffect(() => {
        setEdit(props.edit);
    }, [props.edit])

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setEdit(false);
            props.disableEdit(false);
        }
        // if (e.key === 'Enter') {
        //     if (edit && checkForPlausibleNewValues()) {
        //         console.log('update new Values');
        //     }
        // }
    }

    const checkForPlausibleNewValues = (values) => {
        const val = values || newValues;
        let check = true;
        if (val.Item && val.Item.trim() !== '') {
            setValueWarnings({ ...valueWarnings, Item: false });
        } else {
            setValueWarnings({ ...valueWarnings, Item: true });
            check = false;
        }
        if (val.Description && val.Description.trim() !== '') {
            setValueWarnings({ ...valueWarnings, Description: false });
        } else {
            setValueWarnings({ ...valueWarnings, Description: true });
            check = false;
        }
        if (val.Price && Number(val.Price > 0)) {
            setValueWarnings({ ...valueWarnings, Price: false });
        } else {
            setValueWarnings({ ...valueWarnings, Price: true });
            check = false;
        }
        if (!newValues.Restaurant_ID) {
            return false;
        }
        if (!check) {
            return false;
        }
        return true;
    }

    const onChange = (key, value) => {
        checkForPlausibleNewValues({ [key]: value });
        setNewValue({ ...newValues, [key]: value });
    }

    const handleSubmit = () => {
        console.log(checkForPlausibleNewValues());
        if (checkForPlausibleNewValues()) {
            const fd = new FormData();
            fd.append('data', JSON.stringify(newValues));

            props.saveData({
                route   : 'saveNewMenuItem',
                formData: fd,
                callBack: completeSaveNewMenuItem,
                response: props.response,
            })
        }
    }

    const completeSaveNewMenuItem = (data) => {
        endEdit().then(() => {
            console.log(data);
            const socket_action = {
                action: 'completeSaveNewMenuItem',
                data,
            };
            props.socket.emit('action', props?.Restaurant_ID, JSON.stringify(socket_action));
        });
    }

    const endEdit = async () => {
        await props.disableEdit(false);
        setNewValue(initialNewValues);
        setEdit(false);
    }

    const toggleEdit = () => {
        if (!edit) {
            setEdit(true);
            props.disableEdit(true);
        }
    }

    const deleteMenuItem = () => {
        if (props.item.ID) {
            const fd = new FormData();
            fd.append('ID', props.item.ID);

            props.saveData({
                route   : 'deleteMenuItem',
                formData: fd,
                callBack: completeDeleteMenuItem,
                response: props.response,
            })
        }
    }

    const completeDeleteMenuItem = (data) => {
        props.completeDeleteMenuItem({
            type_id: props.type_id,
            ID     : data.ID,
        })
    }

    const type = props.Type && props.Type.slice(0, -1).toLowerCase();

    if(!props.Restaurant_ID) {
        return (
            <LoadingContainer />
        )
    }

    return (
        <Form submit={handleSubmit}>
            <div className="myMenu__list__container__item" onClick={toggleEdit}>
                <div className="flex flex--15">
                    {edit && !props.edit &&
                        <FontAwesomeIcon className="myMenu__list__container__item__delIcon" icon={faTimes} color={'red'}
                                         title={'Delete Item'} onClick={deleteMenuItem}/>
                    }
                    <div>
                        {edit ?
                            <div>
                                <Input
                                    className={valueWarnings.Item ? 'red' : ''}
                                    placeholder={'New ' + type}
                                    name={'Item'}
                                    onChange={onChange}
                                    value={newValues.Item}
                                    autoFocus
                                    invisible
                                />
                            </div>
                            :
                            <div>{props?.item?.Item}</div>
                        }
                        {edit ?
                            <div className="myMenu__list__container__item__desc">
                                <Input
                                    className={valueWarnings.Description ? 'small_font italic red' : 'small_font italic'}
                                    placeholder={'Tell something about your new ' + type}
                                    name={'Description'}
                                    onChange={onChange}
                                    value={newValues.Description}
                                    invisible
                                />
                            </div>
                            :
                            <div className="myMenu__list__container__item__desc">{props?.item?.Description}</div>
                        }
                    </div>
                </div>
                {edit ?
                    <div className="flex">€
                        <Input
                            type={'number'}
                            className={valueWarnings.Price ? 'short_width red' : 'short_width'}
                            placeholder={'0.00'}
                            name={'Price'}
                            onChange={onChange}
                            value={newValues.Price}
                            invisible
                        />
                    </div>
                    :
                    <div>€{props.item && props.item.Price && Number(props.item.Price).toFixed(2)}</div>
                }
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    Restaurant_ID: state.user?.data?.Restaurant_ID,
})

const mapDispatchToProps = {
    saveData,
    response,
    completeSaveNewMenuItem,
    completeDeleteMenuItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMenuListElements)