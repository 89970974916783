import {createReducer, createAction} from "@reduxjs/toolkit";

export const storeMenuList = createAction('STORE_MENU_LIST');
export const completeSaveNewMenuItem = createAction('COMPLETE_SAVE_NEW_MENU_ITEM');
export const completeDeleteMenuItem = createAction('COMPLETE_DELETE_MENU_ITEM');

const restaurant = {
    menu: {
        list: {}
    }
}

const RestaurantReducer = createReducer(restaurant, (builder => {
    builder
        .addCase(storeMenuList, (state, action) => {
            state.menu.list = action.payload;
        })
        .addCase(completeSaveNewMenuItem, (state, action) => {
            if(!state.menu.list[action.payload.type_id]['Items']) {
                state.menu.list[action.payload.type_id]['Items'] = {};
            }
            state.menu.list[action.payload.type_id]['Items'][action.payload.ID] = action.payload;
        })
        .addCase(completeDeleteMenuItem, (state, action) => {
            delete state.menu.list[action.payload.type_id]['Items'][action.payload.ID];
        })
}))

export default RestaurantReducer;