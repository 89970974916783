import {connect} from "react-redux";
import './myrestaurant.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faList, faQrcode} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import MyMenuContainer from "./MyMenu/MyMenuContainer";
import Test from "../Test/Test";
import {NavLink, useParams} from "react-router-dom";
import config from "../config";

const MyRestaurantContainer = (props) => {
    const params = useParams();

    const [option, setOption] = useState(null);

    const optionsList = [
        {
            id       : 0,
            label    : 'My Menu',
            icon     : faBook,
            path     : 'myMenu',
            component: <MyMenuContainer/>
        },
        {
            id       : 1,
            label    : 'My QR',
            icon     : faQrcode,
            path     : 'myQR',
            component: <Test />
        },
    ];

    useEffect(() => {
        const optionID = params && params.path ? optionsList.find((item) => (item.path === params.path)).id : null;
        setOption(optionID)
    },[params])

    return (
        <div className="myRestaurant">
            <div className="myRestaurant__list__container">
                <div className="myRestaurant__list__header">
                    <div>
                        <div>
                            Fancy Pies
                        </div>
                        <div className={'fontSize--12'}>
                            1230 Vienna
                        </div>
                    </div>
                </div>
                {optionsList.map((item, index) => (
                    <NavLink className="myRestaurant__list__element" key={index} to={'/myRestaurant/' + item.path}>
                        <FontAwesomeIcon icon={item.icon}/>
                        <div>{item.label}</div>
                    </NavLink>
                ))}
            </div>
            <div className="myRestaurant__content__container">
                {optionsList[option] && optionsList[option].component ?
                    optionsList[option].component
                    :
                    null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MyRestaurantContainer)