import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import config from "../config";
import Input from "../Base/BaseComponents/Input";
import Form from "../Base/Form";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: config.serverBaseURL,
            },
        });

        if (result.error) {
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <Form submit={handleSubmit}>
            <PaymentElement />
            <Input
                type={'submit'}
                value={'Comfirm Payment'}
                disabled={!stripe}
            />
        </Form>
    )
};

export default CheckoutForm;