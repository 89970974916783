import UserReducer from "./reducers/UserReducer";
import ModalReducer from "./reducers/ModalReducer";
import PaymentReducer from "./reducers/PaymentReducer";
import RestaurantReducer from "./reducers/RestaurantReducer";

const rootReducer = (state = {}, action) => ({
    user      : UserReducer(state.user, action),
    modal     : ModalReducer(state.modal, action),
    payment   : PaymentReducer(state.payment, action),
    restaurant: RestaurantReducer(state.restaurant, action),
})

export default rootReducer;