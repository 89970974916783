import './test.scss';
import {useEffect, useState} from "react";

const Test = (props) => {
    const socket = props.socket;
    const [msg,setMsg] = useState('')
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const handleNewMessage = (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        };

        socket.on('message', handleNewMessage);

        socket.emit('joinRoom', '123');

        return () => {
            socket.off('message', handleNewMessage);
            socket.disconnect();
        };
    }, []);

    const sendMessage = (e) => {
        if(e.key === 'Enter') {
            socket.emit('message','123',e.target.value);
            setMsg('');
        }
    }

    return (
        <div className="test">
            <input type="text" onKeyDown={(e) => sendMessage(e)} onChange={(e) => setMsg(e.target.value)} value={msg} />
            <div className="test__chat">
                {messages.map((item) => (
                    <div>{item}</div>
                ))}
            </div>
        </div>
    )
}

export default Test;