const Form = (props) => {

    const submit = (e) => {
        e.preventDefault();
        props.submit(e);
    }

    return (
        <form onSubmit={submit} className={'form'}>
            {props.children}
            <input type={'submit'} style={{display: 'none'}} />
        </form>
    )
}

export default Form;