import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import './mymenu.scss';
import {useEffect, useState} from "react";
import {loadData} from "../../functions";
import {response} from "../../Base/reducers/ModalReducer";
import {storeMenuList} from "../../Base/reducers/RestaurantReducer";
import MyMenuListTypes from "./MyMenuListTypes";
import LoadingContainer from "../../Base/Loading/LoadingContainer";
import {io} from "socket.io-client";
import config from "../../config";

const socket = io(config.wss);

const MyMenuContainer = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(Object.keys(props.menuList).length === 0) {
            loadMenu();
        }else{
            setLoaded(true);
        }
    }, [])

    const loadMenu = () => {
        loadData({
            route   : 'getMenuList',
            callBack: setMenuList,
            response: props.response,
        });
    }

    const setMenuList = (data) => {
        setLoaded(true);
        props.storeMenuList(data);
    }

    if (!loaded || !props.menuList) {
        return (
            <LoadingContainer />
        )
    }

    return (
        <div className="myMenu">
            <div className="myMenu__header">
                {Object.keys(props.menuList).map((type) => (
                    <MyMenuListTypes
                        key={type}
                        type={props.menuList[type]}
                        socket={socket}
                    />
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    menuList: state.restaurant.menu.list,
})

const mapDispatchToProps = {
    loadData,
    response,
    storeMenuList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMenuContainer)