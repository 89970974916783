let localhost = false;
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    localhost = true;
}

let test = false;
if (localhost || window.location.hostname === 'test.alexhinterleitner.com') {
    test = true;
}

const config = {
    baseURL      : localhost ? 'http://localhost/qr_web/' : test ? 'https://test.alexhinterleitner.com/' : 'https://qr.fshare.cloud/',
    serverBaseURL: localhost ? 'http://localhost:3000' : test ? 'https://test.alexhinterleitner.com' : 'https://qr.fshare.cloud',
    audioURL     : 'homepage/src/Base/Media/Audio/',
    imageURL     : 'homepage/src/Base/Media/Images/',
    isLocalhost  : localhost,
    isTest       : test,
    isMobile     : {
        Android   : function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS       : function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera     : function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows   : function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any       : function () {
            return (config.isMobile.Android() || config.isMobile.BlackBerry() || config.isMobile.iOS() || config.isMobile.Opera() || config.isMobile.Windows());
        }
    },
    wss          : localhost ? 'https://testwss.alexhinterleitner.com/' : 'https://wss.alexhinterleitner.com/',
}

export default config;