import {createReducer, createAction} from "@reduxjs/toolkit";

export const response = createAction('response');
export const removeResponseItem = createAction('removeResponseItem');

const modal = {
    active: false,
    notifications: {},
    lastInsertedNotificationIndex: null,
}

const ModalReducer = createReducer(modal, (builder) => {
    builder
        .addCase(response, (state, action) => {
            state.active = action.payload.active;
            const index = Object.keys(state.notifications).length;
            state.notifications[index] = {
                title: action.payload.title,
                text: action.payload.text,
            };
            state.lastInsertedNotificationIndex = index;
        })
        .addCase(removeResponseItem, (state,action) => {
            delete state.notifications[state.lastInsertedNotificationIndex];
        })
})

export default ModalReducer;