import Form from "../Base/Form";
import Input from "../Base/BaseComponents/Input";
import {useEffect, useState} from "react";
import {saveData} from "../functions";
import {connect} from "react-redux";

const script = document.createElement('script');
script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBaGkzt7VWHAczdHmns1FTx4uloABC6n-M";
script.async = true;
document.head.appendChild(script);

const NewRestaurantForm = (props) => {
    const initial_sendData = {
        name      : '',
        zip_code: '',
        city: '',
        street: '',
    }

    const [sendData, setSendData] = useState(initial_sendData);
    const [center, setCenter] = useState({lat: 48.232216, lng: 16.400353});

    const submit = (e) => {
        e.preventDefault();
        let fd = new FormData();
        let send_data = {...sendData}
        send_data['center'] = center;
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            route   : 'newRestaurant',
            formData: fd,
            callBack: completeNewRestaurant,
            response: () => null,
        })
    }

    const completeNewRestaurant = (data) => {
        console.log(data);
    }

    const onChange = (key, value) => {
        console.log(key, value);
        let data = {...sendData};
        data[key] = value;
        setSendData(data);
    }

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    /*global google*/
                    window.initMap = initMap({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                (error) => {
                    console.error("Error fetching geolocation: ", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    },[])

    const initMap = (data) => {
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom            : 15,
            center          : data,
            mapTypeId       : 'hybrid',
            disableDefaultUI: true,
        });
        let marker = new google.maps.Marker({
            position: data,
            map,
        });
        google.maps.event.addListener(map, 'click', (event) => setNewMarker(event.latLng.lat(), event.latLng.lng()));
        google.maps.event.addListener(map, 'click', function (event) {
            marker.setMap(null);
            marker = new google.maps.Marker({
                position: event.latLng,
                map,
            })
        });
    }

    const setNewMarker = (lat, lng) => {
        setCenter({lat, lng})
    }

    return (
        <Form submit={submit}>
            <Input
                label={'Restaurant'}
                name={'name'}
                value={sendData.name ?? ''}
                onChange={onChange}
            />
            <Input
                label={'Zip Code'}
                name={'zip_code'}
                value={sendData.zip_code ?? ''}
                onChange={onChange}
            />
            <Input
                label={'City'}
                name={'city'}
                value={sendData.city ?? ''}
                onChange={onChange}
            />
            <Input
                label={'Street'}
                name={'street'}
                value={sendData.street ?? ''}
                onChange={onChange}
            />
            <div id='map' style={{ width: '50vw', height: '50vh' }}/>
            <Input
                type={'submit'}
                name={'restaurant'}
                value={'send'}
            />
        </Form>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    saveData,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRestaurantForm);