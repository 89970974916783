import {Provider} from "react-redux";
import store from "./Base/store";
import AppContent from "./Base/AppContent";
import './Base/Styles/styles.scss';
import React, {useEffect, useState} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import NewRestaurantForm from "./Components/NewRestaurantForm";
import Home from "./Home/Home";
import Account from "./Account/Account";
import Test from "./Test/Test";
import MyRestaurantContainer from "./MyRestaurant/MyRestaurantContainer";
import {io} from "socket.io-client";
import config from "./config";

const socket = io(config.wss);

const App = () => {

    const router = createBrowserRouter([
        {
            path    : '/',
            element : <AppContent/>,
            children: [
                { path: '/', element: <Home/> },
                { path: '/restaurant', element: <NewRestaurantForm/> },
                { path: '/myRestaurant', element: <MyRestaurantContainer socket={socket} /> },
                { path: '/myRestaurant/:path', element: <MyRestaurantContainer socket={socket} /> },
                { path: '/account', element: <Account/> },
                { path: '/test', element: <Test socket={socket} /> },
            ]
        }
    ]);

    return (
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    )
}

export default App;