import {connect} from "react-redux";
import Form from "../Base/Form";
import Input from "../Base/BaseComponents/Input";
import {useState} from "react";

const Login = () => {
    const [loginData, setLoginData] = useState({
        email   : '',
        password: '',
    })

    const onChange = (key, value) => {
        let data = { ...loginData };
        data[key] = value;
        setLoginData(data);
    }

    const submit = () => {

    }

    return (
        <Form onSubmit={submit}>
            <Input
                placeholder={'E-Mail'}
                name={'email'}
                value={loginData.email ?? ''}
                onChange={onChange}
            />
            <Input
                placeholder={'Password'}
                name={'password'}
                value={loginData.password ?? ''}
                onChange={onChange}
            />
            <Input
                type={'submit'}
                name={'submit'}
                value={'Send'}
            />
        </Form>
    )
}

const mapStatToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStatToProps,mapDispatchToProps)(Login)