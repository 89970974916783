import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import './nav.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignIn, faUser} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import Input from "../Base/BaseComponents/Input";
import Form from "../Base/Form";
import Login from "../Account/Login";
import Register from "../Account/Register";

const Nav = (props) => {
    const [popUpOpen,setPopUpOpen] = useState(false);

    const toggleLoginPopUp = () => {
        setPopUpOpen(!popUpOpen);
    }

    const onChange = (key, value) => {
        console.log(key, value);
    }

    const handleLogin = () => {

    }

    return (
        <div className="nav">
            <div>

            </div>
            <div className="nav__main">
                <NavLink to={'/'}>
                    <div className="nav__main__element">Home</div>
                </NavLink>
                <NavLink to={'/test'}>
                    <div className="nav__main__element">Restaurants</div>
                </NavLink>
                <NavLink to={'/myRestaurant'}>
                    <div className="nav__main__element">My Restaurant</div>
                </NavLink>
            </div>
            <div className="nav__login">
                <NavLink to={'/account'}>
                    <FontAwesomeIcon icon={faUser} />
                </NavLink>
                <FontAwesomeIcon icon={faSignIn} onClick={toggleLoginPopUp} />
                {popUpOpen &&
                    <div className="nav__login__popUp">
                        <div className="nav__login__popUp__header">
                            <FontAwesomeIcon icon={faSignIn} />
                            <div>
                                Login
                            </div>
                        </div>
                        <Login />
                        {/*<Register />*/}
                        {/*<Form submit={handleLogin}>*/}
                        {/*    <Input*/}
                        {/*        type={'text'}*/}
                        {/*        name={'username'}*/}
                        {/*        placeholder={'Username'}*/}
                        {/*        onChange={onChange}*/}
                        {/*        value={''}*/}
                        {/*    />*/}
                        {/*    <Input*/}
                        {/*        type={'password'}*/}
                        {/*        name={'password'}*/}
                        {/*        placeholder={'Password'}*/}
                        {/*        onChange={onChange}*/}
                        {/*        value={''}*/}
                        {/*    />*/}
                        {/*    <Input*/}
                        {/*        type={'submit'}*/}
                        {/*        name={'submit'}*/}
                        {/*        onClick={handleLogin}*/}
                        {/*        value={'Login'}*/}
                        {/*    />*/}
                        {/*</Form>*/}
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);