import {createReducer, createAction} from "@reduxjs/toolkit";

export const storeUser = createAction('STORE_USER');

const user = {
    userID: null,
    language: navigator.language,
    userRights: [],
    userType: null,
    access: null,
}

const UserReducer = createReducer(user, (builder) => {
    builder
        .addCase(storeUser, (state, action) => {
            state.data = action.payload;
        })
})

export default UserReducer;