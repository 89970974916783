import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useEffect, useState} from "react";
import {connect} from "react-redux";

import CheckoutForm from "./CheckoutForm";
import {togglePaymentPopUp} from "../Base/reducers/PaymentReducer";

import './payments.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

const stripePromise = loadStripe('pk_test_51OfVKGDFE8imFCHc2rQKV20sYG8NCE1h53wP7mLivTR9KU8ltN8OyJO4OO1Az9nq6tIecabMXgJcxF55PJpEcIMt00VLDnfGwZ');

const PaymentContainer = (props) => {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        fetch('https://stripe.alexhinterleitner.com/create-payment-intent', {
            method : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body   : JSON.stringify({
                amount  : props.amount,
                currency: 'eur',
            }),
        })
            .then(res => res.json())
            .then(data => {
                setClientSecret(data.clientSecret);
            })
            .catch(error => {
                console.error("Fehler beim Abrufen des clientSecret:", error);
            });

    }, [])

    const options = {
        clientSecret: clientSecret,
    }

    const closePayment = () => {
        props.togglePaymentPopUp({
            open: false,
        });
    }

    const getPaymentAmount = () => {
        return (Number(props.amount)/100).toFixed(2);
    }

    if(!clientSecret || !props.amount) return null;

    return (
        <div className="payments">
            <div className="payments__container">
                <div className="payments__container__closeIcon" onClick={closePayment}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="payments__container__confirm">
                    <div><FontAwesomeIcon icon={faCheck} /> Zahlung von <span className="payments__container__confirm__amount">€{getPaymentAmount()}</span> bestätigen</div>
                </div>
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                </Elements>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    amount: state.payment.amount,
})

const mapDispatchToProps = {
    togglePaymentPopUp,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);