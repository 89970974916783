const Input = (props) => {

    const onChange = (e) => {
        props.onChange(props.name, e.target.value);
    }

    let classString = 'input';
    if(props.invisible) {
        classString = 'invisible_input';
    }

    return (
        <div className={classString}>
            {props.label &&
                <label htmlFor={props.name ?? null}>{props.label}</label>
            }
            <input step={'any'} placeholder={props.label ?? props.placeholder} type={props.type || 'text'} value={props.value} checked={props.value} onChange={onChange} onClick={props.onClick} autoFocus={props.autoFocus} className={props.className} />
        </div>
    )
}

export default Input;