import React, {useEffect} from "react";
import {connect} from "react-redux";
import {storeUser} from "./reducers/UserReducer";
import {loadData} from "../functions";
import {removeResponseItem, response} from "./reducers/ModalReducer";
import ModalContainer from "./Modal/ModalContainer";
import Nav from "../Nav/Nav";
import {Outlet} from "react-router-dom";
import PaymentContainer from "../Stripe/PaymentContainer";

const AppContent = (props) => {
    useEffect(() => {
        initial_load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initial_load = () => {
        props.loadData({
            route             : 'getUserData',
            callBack          : props.storeUser,
            response          : props.response,
            removeResponseItem: props.removeResponseItem,
        })
    }

    return (
        <div className={'main'}>
            <Nav/>
            <Outlet/>
            {props.payment.open &&
                <PaymentContainer />
            }
            {props.modal.active &&
                <ModalContainer/>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userData: state.user.data,
    modal   : state.modal,
    payment : state.payment,
})

const mapDispatchToProps = {
    storeUser,
    loadData,
    response,
    removeResponseItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent)