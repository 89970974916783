import {connect} from "react-redux";
import './home.scss';
import {togglePaymentPopUp} from "../Base/reducers/PaymentReducer";

const Home = (props) => {
    const openPayment = () => {
        props.togglePaymentPopUp({
            open  : true,
            amount: 123,
        })
    }

    return (
        <div className="home">
            <div className="home__header">
                <div className="home__header__content">
                    <h1 className="home__header__content__text">Welcome to Your Name here</h1>
                    <div className="button home__header__content__button" onClick={openPayment}>Get started</div>
                </div>
                <div className="boxes">
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                    <div className="boxes__element">
                        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    togglePaymentPopUp
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);