import {connect} from "react-redux";
import './modal.scss';

const ModalContainer = (props) => {
    return (
        <div className="modal">
            {Object.keys(props.modal.notifications).map((item) => (
                <div className="modal__notification" key={item}>
                    <div className="modal__notification__title">{props.modal.notifications[item].title}</div>
                    <div className="modal__notification__text">{props.modal.notifications[item].text}</div>
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({
    modal: state.modal,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)