import {connect} from "react-redux";
import Form from "../Base/Form";
import Input from "../Base/BaseComponents/Input";
import {useState} from "react";

const Register = () => {
    const [type, setType] = useState(0); //0 = User, 1 = Company
    const [registerData, setRegisterData] = useState({
        0: { //User
            lastName: {
                placeholder: 'Nachname',
                value: '',
            },
            preName: {
                placeholder: 'Vorname',
                value: '',
            },
            email   : {
                placeholder: 'E-Mail',
                value: '',
            },
            password: {
                placeholder: 'Passwort',
                value: '',
            },
        },
        1: { //Company
            name: {
                placeholder: 'Name des Restaurants',
                value: '',
            },
            password: {
                placeholder: 'Passwort',
                value: '',
            },
        }
    })

    const onChange = (key, value) => {
        let data = { ...registerData };
        data[type][key] = value;
        setRegisterData(data);
    }

    const submit = () => {

    }
    return (
        <Form onSubmit={submit}>
            {Object.keys(registerData[type]).map((key) => (
                <Input
                    placeholder={registerData[type][key].placeholder}
                    name={key}
                    value={registerData[type][key].value}
                    onChange={onChange}
                    key={key}
                />
            ))}
            <Input
                type={'submit'}
                name={'submit'}
                value={'Send'}
            />
        </Form>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps,mapDispatchToProps)(Register)