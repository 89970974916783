import {connect} from "react-redux";
import './account.scss';
import {useState} from "react";
import LogForm from "./LogForm";

const Account = (props) => {
    const [type,setType] = useState(0);

    const handleTypeChange = (type) => {
        setType(type);
    }

    return (
        <div className="account">
            {!props.access ?
                    <LogForm
                        handleTypeChange={handleTypeChange}
                        type={type}
                    />
                :
                <div>Account</div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userRights: state.user.userRights,
    access    : state.user.access,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Account);