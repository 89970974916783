import {connect} from "react-redux";
import MyMenuListElements from "./MyMenuListElements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

const MyMenuListTypes = (props) => {
    const [edit,setEdit] = useState(false);
    const [disableEdit, setDisableEdit] = useState(false);
    const [stopEdit, setStopEdit] = useState(false);

    const addNewMenuItem = () => {
        if(!disableEdit) {
            setEdit(!edit);
        }else{
            setStopEdit(!stopEdit);
            setDisableEdit(false);
        }
    }

    const toggleDisableEdit = (toggle) => {
        if(!toggle) {
            setEdit(false);
        }
        setDisableEdit(toggle);
    }

    return (
        <div className="myMenu__type__container">
            <div className={"myMenu__type__container__banner myMenu__type__container__banner--" + props.type.ID}>
                <div>
                    {props.type.Type}
                </div>
                <div className={edit || disableEdit ? "myMenu__type__container__banner__addIcon myMenu__type__container__banner__addIcon--close" : "myMenu__type__container__banner__addIcon"} onClick={addNewMenuItem}>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </div>
            <div className="myMenu__list__container">
                {props.type.Items && Object.keys(props.type.Items).map((item) => (
                    <MyMenuListElements
                        key={item}
                        item={props.type.Items[item]}
                        disableEdit={toggleDisableEdit}
                        stopEdit={stopEdit}
                        type_id={props.type.ID}
                        socket={props.socket}
                    />
                ))}
                {edit &&
                    <MyMenuListElements
                        edit
                        Type={props.type.Type}
                        disableEdit={toggleDisableEdit}
                        stopEdit={stopEdit}
                        type_id={props.type.ID}
                        socket={props.socket}
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MyMenuListTypes)