import {connect} from "react-redux";
import Login from "./Login";
import Register from "./Register";

const LogForm = (props) => {
    return (
        <div className="account__box">
            <div className="account__box__header">
                <div className="account__box__header__element" onClick={() => props.handleTypeChange(0)}>Login</div>
                <div className="account__box__header__element" onClick={() => props.handleTypeChange(1)}>Register</div>
            </div>
            {props.type === 0 ?
                <Login />
            :
                <Register />
            }
        </div>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LogForm)