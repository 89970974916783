import {createReducer, createAction} from "@reduxjs/toolkit";

export const togglePaymentPopUp = createAction('TOGGLE_PAYMENT_POP_UP');
export const setPaymentAmount = createAction('SET_PAYMENT_AMOUNT');

const payment = {
    open: false,
    amount: null,
}

const PaymentReducer = createReducer(payment, (builder) => {
    builder
        .addCase(togglePaymentPopUp, (state, action) => {
            if(!action.payload.open) {
                state.open = !state.open;
            }else{
                state.open = action.payload.open;
            }
            state.amount = action.payload.amount || null;
        })
        .addCase(setPaymentAmount, (state, action) => {
            state.amount = action.payload;
        })
})

export default PaymentReducer;